import { Route } from '@angular/router';

import { provideOivViewer } from '@mobi/oiv-viewer-ng-jslib';
import { B2C, languageResolver, PAGE_URL_PATH, URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';

export const appRoutes: Route[] = [
    {
        path: URL_PATH.AVB,
        redirectTo: PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
    {
        path: PAGE_URL_PATH.NOT_AVAILABLE,
        resolve: { language: languageResolver },
        data: { app: B2C },
        loadComponent: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.NotAvailableComponent),
    },
    {
        path: PAGE_URL_PATH.NOT_FOUND,
        resolve: { language: languageResolver },
        data: { app: B2C },
        loadComponent: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.NotFoundPageComponent),
    },
    {
        path: URL_PATH.AVB + '/:urlName',
        resolve: { language: languageResolver },
        data: { app: B2C },
        providers: [provideOivViewer],
        loadChildren: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.MainLayoutModule),
    },
    {
        path: '',
        redirectTo: PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
];
